<template>
  <div class="page-view info">
    <div class="container mx-auto px-4 sm:px-2">
      <!-- <div class="text-center my-6 w-full">
        <a class="btn primary-btn" href="https://www.genertellife.it/landingpage/lp-valory/" target="_blank">
          Maggiori informazioni
        </a>
      </div> -->
      <div class="text-center"><!-- flex-wrapper -->
        <!-- <div class="item"> -->
          <h1 class="block text-center text-6xl font-bold mt-20">404<small class="mt-2 block text-primary-magenta text-3xl">Page not found</small></h1>
          <!-- <router-link to="/">Torna alla Homepage</router-link> -->
          <!-- <h1 class="page-title">
            {{
            ended ? 'L\'iniziativa Cashback di Genertellife è terminata in data 30 giugno 2024.' :
            'Cashback di Genertellife*'
            }}
          </h1>
          <div class="page-content" v-if="!ended">
            <p>
              Tutela i tuoi risparmi con Genertellife e, dopo l’acquisto, ottieni un Cashback direttamente sul tuo conto
              corrente.
            </p>
            <h3>Come funziona</h3>
            <p>Ottenere il tuo Cashback è facile:
            </p>
            <ol>
              <li><b>Acquista una polizza di investimento e/o una polizza Risparmio </b> nel rispettivo periodo
                promozionale</li>
              <li><b>Ricevi l’e-mail</b> con il link personalizzato</li>
              <li><b>Accedi al link</b> entro 30 giorni, e indica il tuo IBAN per ottenere il bonifico</li>
              <li><b>Utilizza il link</b> per tenere monitorata la situazione del tuo Cashback</li>
              <li><b>Leggi i periodi di effettuazione dei rimborsi</b> su <b>Termini & Condizioni</b> delle rispettive
                iniziative
              </li>
            </ol>
            <hr />
            <h3>Condizioni delle iniziative</h3>
            <ul>
              <li>
                <a href="/termini_condizioni_2024_1.pdf" target="_blank" class="underline hover:no-underline">Termini e condizioni Genertellife dal 01/02/2024 al 07/04/2024</a>
              </li>
            </ul>
          </div>
          <div class="page-content" v-else>
            <p>Grazie per aver partecipato.</p>
          </div> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
moment.locale('it')

export default {
  data () {
    return {
      ended: false
    }
  },
  methods: {
    checkEnd () {
      const today = moment()
      const end = moment(process.env.VUE_APP_END_DATE)
      if (today.isAfter(end)) {
        this.ended = true
      }
    }
  },
  mounted () {
    this.checkEnd()
  }
}
</script>

<style scoped lang="scss">
  @import "@/assets/scss/forms.scss";
  @import "@/assets/scss/tables.scss";
  @import "@/assets/scss/views/info.scss";
</style>
